<template>
  <div>
    <div class="footer-1 p-md-5" id="footer">
      <div class="row">
        <div class="col-md-3">
          <h3><img src="../assets/images/pin-icon.svg"> Predajné miesto</h3>
          <p class="font-weight-bold mb-0">Penta Real Estate</p>
          <p class="m-0">Digital Park II</p>
          <p class="m-0">Einsteinova 23</p>
          <p class="m-0">Bratislava</p>
          <p class="m-0">Slovenská republika</p>
          <p class="m-0"><a target="_blank" href="https://www.google.com/maps/place/Digital+Park+II,+Einsteinova+23,+851+01+Petr%C5%BEalka/@48.1301763,17.1050219,17z/data=!3m1!4b1!4m5!3m4!1s0x476c897221a35f19:0xfceab18ad724e22!8m2!3d48.1301763!4d17.1072106">Zobraziť na mape</a></p>

          <p class="font-weight-bold mb-0 mt-4">Otváracie hodiny</p>
          <p class="m-0">Pondelok – Piatok: 08.30 – 17.00</p>
          <p class="m-0">Sobota – Nedeľa: ZATVORENÉ</p>

          <p class="mt-4 mb-1 font-weight-bold">Osobné stretnutie je ideálne dohodnúť si vopred.</p>
          <p class="m-0 font-weight-bold">Našich predajcov môžete kontaktovať aj telefonicky <br>alebo na mailovej adrese <a href="mailto:byvanie@borybyvanie.sk">byvanie@borybyvanie.sk</a>.</p>
        </div>
        <div class="col-md-3 mb-md-0"></div>
        <div class="col-md-3 mb-md-0"></div>
        <div class="col-md-3 pt-5 pt-md-0" id="contact-footer">
          <h3><img class="contact-icon" src="../assets/images/phone-call.png"> Kontakt</h3>
          <div class="row">
            <div class="col-md-6 mb-3 mb-md-0" v-if="!bory4 || showAll">
              <div v-if="bory2 || showAll">
                <p class="font-weight-bold mb-2 stage">Bory Nový Dvor</p>
                <p class="m-0">Jana Miková</p>
                <p class="m-0"><a href="tel:+421910612765">+421 910 612 765</a></p>

                <p class="m-0 mt-3">Kamila Kordošová</p>
                <p class="m-0"><a href="tel:+421903590023">+421 903 590 023</a></p>
              </div>
            </div>
            <div class="col-md-6" v-if="bory4 || showAll">
              <p class="font-weight-bold mb-2 stage">Bory Na Hrádzi</p>
              <p class="m-0">Zuzana Bábelová</p>
              <p class="mb-3"><a href="tel:+421904722539">+421 904 722 539</a></p>
              <p class="m-0">Andrea Seléni</p>
              <p class="m-0"><a href="tel:+421904651427">+421 904 651 427</a></p>
            </div>
          </div>
          <p class="mt-4"><a href="mailto:byvanie@borybyvanie.sk">byvanie@borybyvanie.sk</a></p>

          <p class="mt-4"><a href="https://www.facebook.com/Borybyvanie/" target="_blank" class="d-flex align-items-center"><img class="mr-2" src="../assets/images/facebook-icon.svg"> Facebook</a></p>
          <h3 class="mt-5 mb-3">Klientsky portál</h3>
          <b-button class="text-white" href="https://www.livingbypenta.sk/login" target="_blank" squared block variant="primary">Prihlásiť sa</b-button>
        </div>
      </div>
      <div class="row mt-5 mt-md-0 bottom-links">
        <div class="col-12">
          <p class="font-weight-bold">Všetky vizualizácie zobrazené na stránke sú ilustračné.</p>
          <a class="mr-3 d-block d-md-inline" v-b-modal.agreements>Spracovanie osobných údajov</a>
          <a class="mr-3" v-b-modal.cookies>Cookies</a>
          <a class="mr-3" style="cursor:pointer" @click="openMoreOptions()">Upraviť nastavenia cookies</a>
          <a>Design by Milk Design Directors</a>
        </div>
      </div>
    </div>
    <div class="footer-2 px-3 px-md-5 py-4">
      <div class="row align-items-center">
        <div class="col-md-4 mb-3 mb-md-0 mobile-last text-center text-md-left">
          <p class="m-0">© All rights reserved by PENTA REAL ESTATE</p>
        </div>
        <div class="col-md-4 mb-4 mb-md-0 text-center">
          <img src="../assets/images/penta-re-logo.svg" alt="Penta real estate">
        </div>
        <div class="col-md-4 mb-4 mb-md-0 d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-right social-icons">
          <a href="https://www.pentarealestate.com/sk/" class="social penta ml-0" target="_blank"></a>
          <a href="https://www.facebook.com/RealEstatePenta/" class="social fb" target="_blank"></a>
          <a href="https://www.instagram.com/penta_real_estate/" class="social ig" target="_blank"></a>
          <a href="https://www.linkedin.com/company/28652117/" class="social in" target="_blank"></a>
        </div>
      </div>
    </div>
    <b-modal id="cookies" scrollable hide-footer title="Zásady používania súborov cookies na webovej stránke www.borybyvanie.sk" size="xl">
      <CookiesModal></CookiesModal>
    </b-modal>
    <b-modal id="agreements" scrollable hide-footer title="PRAVIDLÁ SPRACÚVANIA OSOBNÝCH ÚDAJOV" size="xl">
      <AgreementsModal></AgreementsModal>
    </b-modal>
  </div>
</template>

<script>
import CookiesModal from "@/components/CookiesModal";
import AgreementsModal from "@/components/AgreementsModal";
export default {
  name: "Footer",
  components: {AgreementsModal, CookiesModal},
  data() {
    return {
      bory2: false,
      bory3: false,
      bory4: false,
      showAll: true
    }
  },
  created() {
    if (this.$router.currentRoute.path == '/byvanie/novy-dvor' || this.$router.currentRoute.params.stage == "bory2b") {
      this.bory2 = true
      this.showAll = false
    }
    else if (this.$router.currentRoute.path == '/byvanie/bory3' || this.$router.currentRoute.params.stage == "bory3") {
      this.bory3 = true
      this.showAll = false
    }
    else {
      this.bory2 = false
      this.bory3 = false
      this.bory4 = false,
      this.showAll = true
    }
  },
  methods: {
    async openMoreOptions(){
			this.$root.$emit('bv::toggle::collapse', 'cookie-consent-sidebar')
			this.noClose = true
		}
  },
  watch:{
    $route (to){
      this.bory2 = false
      this.bory3 = false
      this.bory4 = false
      if (to.path == '/byvanie/novy-dvor' || to.params.stage == "bory2b") {
        this.bory2 = true
        this.showAll = false
      }
      else if (to.path == '/byvanie/bory3' || to.params.stage == "bory3") {
        this.bory3 = true
        this.showAll = false
      }
      else if (to.path == '/byvanie/na-hradzi' || to.params.stage == "bory4") {
        this.bory4 = true
        this.showAll = false
      }
      else {
        this.bory2 = false
        this.bory3 = false
        this.bory4 = false
        this.showAll = true
      }
    }
  }
}
</script>

<style lang="sass" scoped>
$md: 768px
.footer-1
  background: #21252B
  @media (max-width: $md)
    padding: 30px
  h3
    font-size: 18px
    line-height: 22px
    margin-bottom: 30px
    display: flex
    align-items: center
    @media (max-width: $md)
      margin-bottom: 15px
    img
      margin-right: 10px

      &.contact-icon
        max-height: 20px
  p
    font-size: 16px
    color: #D5DBDF
    font-weight: 500
    &.font-weight-bold
      color: #fff

    &.stage
      @media (max-width: $md)
        font-size: 20px
  a
    color: var(--primary)
  .bottom-links
    font-size: 14px
    a
      color: var(--grey)
      font-weight: 500
.footer-2
  color: var(--text-dark)
  p
    font-size: 14px
    font-weight: 500
    color: #000
  .social-icons
    .social
      width: 26px
      height: 26px
      display: inline-block
      background: url(../assets/images/social-icons.svg) no-repeat
      margin-left: 8px
      &.penta
        background: url(../assets/images/penta-icon.svg) no-repeat
      &.ig
        background-position: center
      &.in
        background-position: right
  .mobile-last
    @media (max-width: $md)
      order: 3
</style>