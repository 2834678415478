<template>
  <div class="flats-page" :class="{withTopBar: isTopBar}">
    <HomeMap />
    <section class="temp-block px-3 px-md-0 py-3" id="akcia">
      <div class="d-flex flex-wrap px-0 pl-md-5 pr-md-3 justify-content-between">
        <div class="col-12 col-lg-4 mb-3 mb-lg-0 px-0 py-lg-5">
          <h2 class="mb-5 pt-lg-5">Nový rok, <br>staré ceny</h2>
          <p class="mb-5 pr-lg-5">Využite možnosť kúpy bytu za pôvodnú cenu z&nbsp;roku 2024 a ušetrite tak napriek zvyšovaniu DPH. Pôvodné ceny bytov je možné využit do&nbsp;konca februára 2025.</p>
          <p class="pb-lg-5">Bližšie informácie nájdete <br>v <a target="_blank" href="../../docs/podmienky-akcie-novy-rok-stare-ceny.pdf">Podmienkach akcie Nový rok, staré ceny.</a></p>
        </div>
        <div class="col-12 col-lg-7 px-0">
          <div class="img"></div>
        </div>
      </div>
    </section>
    <section class="flats" id="tabulka">
      <FlatsTable :show-vr="true"></FlatsTable>
    </section>
  </div>
</template>

<script>

import FlatsTable from "@/components/FlatsTable";
import HomeMap from "@/components/maps/HomeMapNew";

function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export default {
  name: "Flats",
  components: {HomeMap, FlatsTable},
  data() {
    return {
      isTopBar: true
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    const anchor = this.$router.currentRoute.hash;
    this.$nextTick(() => {
      if (anchor && document.querySelector(anchor)) {
        location.href = anchor;
      }
    });
    this.isTopBar = getCookie('hideTopBar') == 1 ? false : true
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

.temp-block
  background: var(--blue)

  h2
    font-weight: bold
    font-size: 80px
    line-height: 90px

    @media (max-width: $md)
      font-size: 46px
      line-height: 54px

  p
    font-size: 20px
    line-height: 24px

    a
      font-weight: 900
      color: #fff
      text-decoration: underline

  .img
    width: 100%
    height: 100%
    background: url("../assets/images/novy-rok-stare-ceny.jpg") no-repeat center
    background-size: cover
    @media (max-width: $md)
      min-height: 250px
      margin-bottom: 20px

.flats-page
  padding-top: 85px
  background: #2D323A

  &.withTopBar
    padding-top: 127px

  @media (max-width: $md)
    padding-top: 45px

</style>
