<template>
  <div>
      <div class="cookies-page">
          <div class="container">
              <div class="row">
                  <div class="col-12 my-5">
                    <div id="CookieDeclaration" lang="sk" dir="ltr"></div>
                        <h1 class="mb-4">Zásady používania súborov cookies na webovej stránke www.borybyvanie.sk</h1>
                        <p>Tieto zásady používania súborov cookies sa vzťahujú na webovú stránku
                        <strong>www.borybyvanie.sk
                        </strong>, ktorá je
                        prevádzkovaná spoločnosťou
                        <strong>Penta Real Estate, s. r. o.,
                        </strong>so sídlom Digital Park II, Einsteinova 25,
                        Bratislava 851 01, IČO: 51&nbsp;284&nbsp;502, zapísaná v&nbsp;obchodnom registri Okresného súdu Bratislava I, oddiel: Sro, vložka
                        č. 124856/B (ďalej len „
                        <strong>cookies
                        </strong>“).
                        </p>
                        <p>Tieto zásady môžeme priebežne aktualizovať aj bez upozornenia. Preto Vám odporúčame, aby ste sa pravidelne
                            oboznamovali s ich znením.
                        </p>
                        <p>
                            <strong>Čo sú súbory cookies?
                            </strong>
                        </p>
                        <p>Súbory cookies sú malé textové súbory, ktoré pri návšteve webovej stránky ukladá prehliadač v počítací alebo inom
                            zariadení návštevníka ako mobilný telefón či tablet. Súbory cookies okrem iného umožňujú webovej stránke rozpoznať
                            zariadenie používateľa a zapamätať si určité informácie o Vašich reláciách počas Vášho pripojenia. Súbory cookies si
                            pamätajú typ používaného prehliadača alebo zvolené nastavenia, ktoré zostávajú Vašimi predvolenými nastaveniami pri
                            opakovanej návšteve webovej stránky zlepšujúcimi Váš užívateľský komfort.
                        </p>
                        <p>
                            <strong>Na čo používame súbory cookies?
                            </strong>
                        </p>
                        <p>Súbory cookies používame predovšetkým za účelom zlepšenia našich služieb a Vášho zážitku z prezerania tejto webovej
                            stránky. Tieto účely nám pomáha zabezpečiť najmä zber súhrnných štatistických údajov o počte návštevníkov,
                            automatický výber jazyka webu a získavanie údajov o tom, ako sa táto webová stránka využíva. Okrem toho využívame
                            cookies od tretích strán na účely využitia marketingových technológií zameraných na optimalizáciu našej ponuky a
                            potreby jednotlivých návštevníkov nášho webu. Správanie sa pri návšteve webovej stránky sa analyzuje na základe
                            algoritmu tak, aby sme Vám následne mohli adresovať cielené produktové odporúčania vo forme reklamných bannerov
                            a&nbsp;reklamných informácií na internetových stránkach tretích subjektov.
                        </p>
                        <p>
                            <strong>Aké konkrétne typy súborov cookies
                            používame?
                            </strong>
                        </p>
                        <p>Na našom webe využívame nasledovné súbory cookies:
                        </p>
                        <ul>
                            <li>
                            <strong>Dočasné súbory cookies
                            </strong>
                            <p>
                                našom webe, pričom ich funkcia spočíva v&nbsp;tom, že si Vás náš web pamätá počas toho, keď sa na ňom nachádzate.
                                V&nbsp;momente kedy náš web opustíte alebo zatvoríte webový prehliadač, tak sa tieto súbory cookies automaticky
                                zmažú. Vo webovom prehliadači môžete tieto súbory cookies blokovať, avšak upozorňujeme na to, že v&nbsp;takom
                                prípade
                                nemusí náš web alebo niektorého jeho časti správne fungovať.
                            </p>
                            <p>
                                Tieto súbory cookies sú identifikujú Váš pohyb na
                            </p>
                            </li>
                            <li>
                            <strong>Permanentné cookies
                            </strong>
                            <p>Tieto súbory cookies majú na našom webe viaceré formy, pričom ide o - analytické, reklamné, technické a
                                cookies
                                tretích strán. Permanentné cookies zostávajú narozdiel od dočasných súborov cookies uchované na
                                Vašom&nbsp;harddisku
                                (najdlhšia doba uchovávania je maximálne 2 roky od poslednej návštevy), pričom na ich uloženie nemá vplyv
                                zatvorenie
                                prehliadača alebo vypnutie počítača. Tieto cookies slúžia na zvýšenie užívateľského komfortu pri využívaní
                                online
                                služieb, prípadne slúžia na štatistické alebo reklamné účely. Tieto cookies môžu obsahovať anonymný
                                identifikátor
                                Vášho prehliadača.
                            </p>
                            <ul>
                                <li>
                                <strong>Analytické súbory cookies
                                </strong>
                                <p>Tieto súbory cookies nám pomáhajú získavať údaje týkajúce sa najmä návštev, pôvodu návštev a
                                    výkonnosti webu.
                                    Analytické cookies majú spoznať opakovanú návštevu nášho webu z rovnakého prehliadača na rovnakom
                                    zariadení a
                                    sledovať Vašu aktivitu pri prezeraní nášho webu. Tieto informácie nám následne pomáhajú rozpoznať
                                    technické
                                    problémy, ktoré sa môžu na našom webe vyskytnúť a&nbsp;taktiež aj efektivitu jednotlivých súčastí nášho
                                    webu, na základe
                                    čoho zdokonaľujeme navigáciu na našom webe a&nbsp;tým Váš užívateľský zážitok.
                                </p>
                                </li>
                                <li>
                                <strong>Reklamné súbory cookies
                                </strong>
                                <p>Tieto súbory cookies nám umožňujú zobrazovať cielenú reklamu na našom webe, čo znamená, že sa Vám
                                    bude zobrazovať na
                                    mieru ušitá reklama. Tieto súbory cookies sú anonymizované, čo znamená, že Vás priamo
                                    neidentifikujú, avšak vieme
                                    Vás rozpoznať ako návštevníka a&nbsp;prispôsobiť pre Vás reklamu. Cookies tohto typu&nbsp;ukladáme iba na
                                    základe vášho
                                    súhlasu. Tento súhlas môžete vyjadriť zakliknutím tlačidla „Súhlasím“ v&nbsp;našom cookie boxe.
                                </p>
                                </li>
                                <li>
                                <strong>Technický súborycookies
                                </strong>
                                <p>Tieto súbory cookies nám pomáhajú udržať prehľad o&nbsp;tom, či ste nám udelili súhlas s&nbsp;používaním
                                    reklamných súborov
                                    cookies Tento typ súborov cookies teda používame, aby sme boli schopní zabezpečiť Vami zvolené
                                    preferencie pri
                                    používaní nášho webu.
                                </p>
                                </li>
                                <li>
                                <strong>Cookies tretích strán
                                </strong>
                                <p>Cookies tretích strán sú cookies, ktoré nie sú vytvorené alebo spravované našim webom, ale treťou
                                    nezávislou
                                    stranou
                                    (napr. obchodný partner, poskytovateľ služieb atď.). Na našom webe aktuálne používame tieto cookies
                                    tretích
                                    strán:
                                </p>
                                <ul>
                                    <li>
                                    <p>
                                        <strong>Facebook Pixel
                                        </strong> – na našom webe používame platformu Facebook Pixel, ktorá
                                        používa
                                        cookies s
                                        cieľom individualizovania reklamy (viď. reklamné súbory cookies) a&nbsp;taktiež na analýzu Vašej
                                        návštevy na
                                        našom
                                        webe (viď. analytické súbory cookies). Prostredníctvom technológie Remarketing-Pixel môže
                                        Facebook
                                        návštevníkov
                                        našej internetovej stránky využiť ako cieľovú skupinu pre reklamy Facebook-Ads. Za týmto
                                        účelom
                                        sa na
                                        Vašom
                                        počítači ukladajú cookies. Pre viac informácii ohľadne týchto cookies môžete navštíviť
                                        webové
                                        stránky
                                        prevádzkovateľa platformy
                                        <a href="https://www.facebook.com/business/help/471978536642445?id=1205376682832142">https://www.facebook.com/business/help/471978536642445?id=1205376682832142
                                        </a>.
                                        Udelením Vášho súhlasu s&nbsp;používaním reklamných súborov cookies súhlasíte s&nbsp;tým, že
                                        prevádzkovateľ
                                        platformy
                                        Facebook Pixel a&nbsp;iné tretie strany môžu používať cookies a&nbsp;web beacons, ktoré získavajú
                                        a&nbsp;umiestňujú na
                                        našom
                                        webe a&nbsp;následné informácie a&nbsp;osobné údaje prostredníctvom nich získané&nbsp;kombinovať s&nbsp;inými
                                        informáciami
                                        za účelom
                                        poskytovania individualizovanej reklamy spoločností patriacich do ekonomickej skupiny
                                        prevádzkovateľa
                                        tejto web
                                        stránky. Tento súhlas môžete kedykoľvek odvolať vo Vašom webovom prehliadači alebo na
                                        stránkach
                                        <a href="http://www.aboutads.info/choices">http://www.aboutads.info/choices
                                        </a> alebo
                                        <a href="http://www.youronlinechoices.eu">http://www.youronlinechoices.eu
                                        </a>.
                                    </p>
                                    </li>
                                    <li>
                                    <p>
                                        <strong>GoogleAnalytics
                                        </strong> - na našom webe používame platformu Google Analytics,
                                        ktorá
                                        používa cookies na analýzu Vašej návštevy na našom webe (viď. analytické súbory cookies).
                                        Pre
                                        viac
                                        informácii
                                        ohľadne týchto cookies môžete navštíviť webové stránky prevádzkovateľa platformy
                                        <a href="https://support.google.com/analytics/answer/6004245?hl=sk">https://support.google.com/analytics/answer/6004245?hl=sk
                                        </a>
                                    </p>
                                    </li>
                                    <li>
                                    <p>
                                        <strong>Google Ads
                                        </strong> – na našom webe používame platformu Google Ads, ktorá
                                        používa
                                        cookies, s&nbsp;cieľom individualizovania reklamy (viď. reklamné súbory cookies). Prostredníctvom
                                        tejto
                                        platformy Vám
                                        môže Google ponúknuť individualizovanú reklamu. Za týmto účelom sa na Vašom počítači
                                        ukladajú
                                        cookies.
                                        Udelením
                                        súhlasu s&nbsp;používaním reklamných súborov cookies súhlasíte s&nbsp;tým, že môžeme na Vašom
                                        zariadení
                                        ukladať
                                        cookies
                                        prostredníctvom nášho webu za účelom poskytovania individualizovanej reklamy. Tento súhlas
                                        môžete
                                        kedykoľvek
                                        odvolať vo Vašom webovom prehliadači alebo na stránkach
                                        <a href="http://www.aboutads.info/choices">http://www.aboutads.info/choices
                                        </a> alebo
                                        <a href="http://www.youronlinechoices.eu">http://www.youronlinechoices.eu
                                        </a>.
                                    </p>
                                    </li>
                                </ul>
                                </li>
                            </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>Ako môžem súbory cookies kontrolovať alebo vymazať?
                            </strong>
                        </p>
                        <p>Väčšina prehliadačov prijíma súbory cookies automaticky. Ak súbory cookies nechcete používať, budete ich
                            musieť
                            aktívne odstrániť alebo zablokovať. Ak odmietnete používanie súborov cookies, budete stále schopní navštíviť
                            našu
                            webovú stránku, ale niektoré funkcie nemusia správne fungovať. Pokiaľ si tak prajete urobiť, môžete zvoliť
                            možnosť
                            odmietnuť či blokovať súbory cookies zmenou nastavenia Vášho internetového prehliadača. Pre viac informácii
                            využite
                            návod poskytnutý Vašim internetovým prehliadačom.
                        </p>
                        <p>Táto verzia Zásad používania súborov cookies na webovej stránke bola vydaná dňa 01.05.2020.
                        </p>
                  </div>
              </div>
          </div>
      </div>
      <ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";

export default {
  components: {ContactForm},
}
</script>

<style lang="sass" scoped>
.cookies-page
    padding-top: 85px
    background: #2D323A

    h1
        @media (max-width: 768px)
            font-size: 30px

    h3
        font-size: 22px

    p
        font-size: 16px
        font-weight: 400

    a 
        color: var(--blue)
</style>
