<template>
  <section v-if="loaded">
    <div class="compare-button" v-if="selectedItems.length > 0" @click="toCompareTable">
      <div class="d-flex align-items-center">
        <div class="mr-4">
          <img src="../assets/icons/compare.svg"> 
        </div>
        <div class="mr-4">
          <p class="mb-1">Porovnávanie bytov</p>
          <p class="small-text mb-0">Pridané byty: {{selectedItems.length}}</p>
        </div>
        <div>
          <a>Zobraziť</a>
        </div>
      </div>
      <div class="compare-flats-detail border-top w-100 mt-1 pt-1 d-none d-md-block">
        <div v-for="(flat, id) in compare" :key="id" class="row">
          <div class="col">{{ flat.flat_number_of_rooms }} izb.</div>
          <div class="col">{{ flat.flat_penta_internal_id }}</div>
          <div class="col">{{currency(flat.flat_price)}} €</div>
          <div class="col-2 d-flex justify-content-end"><div class="d-flex align-items-center p-2" @click="removeFromCompare(flatInfo, false)"><img class="delete-item" width="10" src="../assets/icons/close.svg" alt=""></div></div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap flatDetail px-md-5">
      <div class="col-md-12">
        <a href="#" class="back-button mb-4 d-inline-block" @click="backToFlats">Späť na byty</a>
        <div class="d-flex align-items-center">
          <h3 class="flat-title" v-if="flatInfo.flat_number_of_rooms === '1'"> Jednoizbový byt {{flatInfo.flat_penta_internal_id}}</h3>
          <h3 class="flat-title" v-if="flatInfo.flat_number_of_rooms === '1,5'"> Jeden a pol izbový <span v-if="flatInfo.flat_type === '19'">apartmán</span> <span v-else>byt</span>  {{flatInfo.flat_penta_internal_id}}</h3>
          <h3 class="flat-title" v-if="flatInfo.flat_number_of_rooms === '2'"> Dvojizbový byt {{flatInfo.flat_penta_internal_id}}</h3>
          <h3 class="flat-title" v-if="flatInfo.flat_number_of_rooms === '3'"> Trojizbový byt {{flatInfo.flat_penta_internal_id}}</h3>
          <h3 class="flat-title" v-if="flatInfo.flat_number_of_rooms === '4'"> Štvorizbový byt {{flatInfo.flat_penta_internal_id}}</h3>
          <a class="ml-5 btn btn-primary rounded-0 add-to-compare" role="presentation" v-if="isInCompare" @click="removeFromCompare(flatInfo)">Odobrať z porovnania</a>
          <a id="tooltip-target-1" class="ml-5 btn btn-secondary rounded-0 add-to-compare" role="presentation" v-else-if="compare >= 4">Pridať do porovnania</a>
          <a class="ml-5 btn btn-outline-primary rounded-0 add-to-compare" href="#" role="presentation" @click="addToCompare(flatInfo)" v-else>+ Pridať do porovnania</a>
          <b-tooltip target="tooltip-target-1" triggers="hover">
            Nie je možné pridať do porovnávača. Max. počet bytov v porovnávači: 4
          </b-tooltip>
        </div>
        <b-tabs
            active-nav-item-class="font-weight-bold"
            active-tab-class="font-weight-bold"
            content-class="mt-3"
            nav-wrapper-class="nav-wrapper"
            @input="tabChange($event)"
            v-model="tabIndex"
        >
          <b-tab title="Rozloha" active>
            <div class="row">
              <div class="col-lg-5 pr-md-5 flat-info">
                <div class="d-flex justify-content-between border-bottom py-2 pr-3">
                  <div class="label">BLOK</div>
                  <div v-if="flatInfo.building" class="value">{{flatInfo.building.building_name}}</div>
                </div>
                <div class="d-flex justify-content-between border-bottom py-2 pr-3">
                  <div class="label">PODLAŽIE</div>
                  <div v-if="flatInfo.building" class="value">{{flatInfo.building.floor_number}}</div>
                </div>
                <div class="d-flex justify-content-between border-bottom py-2 pr-3">
                  <div class="label">POČET IZIEB</div>
                  <div class="value">{{flatInfo.flat_number_of_rooms}}</div>
                </div>
                <div class="d-flex justify-content-between border-bottom py-2 pr-3">
                  <div class="label text-uppercase">Úžitková plocha bytu</div>
                  <div class="value">{{flatInfo.flat_area}} m<sup>2</sup></div>
                </div>
                <div class="border-bottom pl-3 py-2 pr-3">
                  <div class="d-flex justify-content-between pl-5">
                    <div class="label">Kúpeľňa</div>
                    <div class="value">{{flatInfo.flat_area_bathroom1}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_bathroom2" class="d-flex justify-content-between pl-5">
                    <div class="label">Kúpeľňa 2</div>
                    <div class="value">{{flatInfo.flat_area_bathroom2}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_bedroom" class="d-flex justify-content-between pl-5">
                    <div class="label">Spálňa</div>
                    <div class="value">{{flatInfo.flat_area_bedroom}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_corridor1" class="d-flex justify-content-between pl-5">
                    <div class="label">Chodba</div>
                    <div class="value">{{flatInfo.flat_area_corridor1}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_corridor2" class="d-flex justify-content-between pl-5">
                    <div class="label">Chodba 2</div>
                    <div class="value">{{flatInfo.flat_area_corridor2}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_hall" class="d-flex justify-content-between pl-5">
                    <div class="label">Chodba</div>
                    <div class="value">{{flatInfo.flat_area_hall}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_kitchen" class="d-flex justify-content-between pl-5">
                    <div class="label">Kuchyňa</div>
                    <div class="value">{{flatInfo.flat_area_kitchen}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_livingroom_kitchen" class="d-flex justify-content-between pl-5">
                    <div class="label">Obývacia izba + Kuchyňa</div>
                    <div class="value">{{flatInfo.flat_area_livingroom_kitchen}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_livingroom" class="d-flex justify-content-between pl-5">
                    <div class="label">Obývacia izba</div>
                    <div class="value">{{flatInfo.flat_area_livingroom}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_room1" class="d-flex justify-content-between pl-5">
                    <div class="label">Izba</div>
                    <div class="value">{{flatInfo.flat_area_room1}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_room2" class="d-flex justify-content-between pl-5">
                    <div class="label">Izba 2</div>
                    <div class="value">{{flatInfo.flat_area_room2}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_sleeping_niche" class="d-flex justify-content-between pl-5">
                    <div class="label">Spací výklenok</div>
                    <div class="value">{{flatInfo.flat_area_sleeping_niche}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_cloakroom" class="d-flex justify-content-between pl-5">
                    <div class="label">Šatňa</div>
                    <div class="value">{{flatInfo.flat_area_cloakroom}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_toilette" class="d-flex justify-content-between pl-5">
                    <div class="label">WC</div>
                    <div class="value">{{flatInfo.flat_area_toilette}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_storage" class="d-flex justify-content-between pl-5">
                    <div class="label">Sklad</div>
                    <div class="value">{{flatInfo.flat_area_storage}} m<sup>2</sup></div>
                  </div>
                </div>
                <div class="d-flex justify-content-between border-bottom py-2 pr-3">
                  <div class="label text-uppercase">EXTERIÉR</div>
                  <div class="value">{{exterierArea}} m<sup>2</sup></div>
                </div>
                <div class="border-bottom pl-3 py-2 pr-3">
                  <div v-if="flatInfo.flat_area_balcony" class="d-flex justify-content-between pl-5">
                    <div class="label">Balkón</div>
                    <div class="value">{{flatInfo.flat_area_balcony}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_loggia" class="d-flex justify-content-between pl-5">
                    <div class="label">Loggia</div>
                    <div class="value">{{flatInfo.flat_area_loggia}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_loggia2" class="d-flex justify-content-between pl-5">
                    <div class="label">Loggia 2</div>
                    <div class="value">{{flatInfo.flat_area_loggia2}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_front_garden" class="d-flex justify-content-between pl-5">
                    <div class="label">Terasa (zelená plocha)</div>
                    <div class="value">{{flatInfo.flat_area_front_garden}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_garden" class="d-flex justify-content-between pl-5">
                    <div class="label">Záhrada</div>
                    <div class="value">{{flatInfo.flat_area_garden}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_external_storage1" class="d-flex justify-content-between pl-5">
                    <div class="label">Exteriérový sklad</div>
                    <div class="value">{{flatInfo.flat_area_external_storage1}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_external_storage2" class="d-flex justify-content-between pl-5">
                    <div class="label">Exteriérový sklad 2</div>
                    <div class="value">{{flatInfo.flat_area_external_storage2}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_terrace" class="d-flex justify-content-between pl-5">
                    <div class="label">Terasa</div>
                    <div class="value">{{flatInfo.flat_area_terrace}} m<sup>2</sup></div>
                  </div>
                  <div v-if="flatInfo.flat_area_terrace2" class="d-flex justify-content-between pl-5">
                    <div class="label">Terasa 2</div>
                    <div class="value">{{flatInfo.flat_area_terrace2}} m<sup>2</sup></div>
                  </div>
                </div>
                <div class="d-flex justify-content-between border-bottom py-2 pr-3">
                  <div class="label">SPOLU (BYT + EXTERIÉR)</div>
                  <div class="value font-weight-bold">{{flatInfo.flat_area_total}} m<sup>2</sup></div>
                </div>
                <div v-if="flatInfo.flat_status === '0'" class="d-flex justify-content-between align-items-center py-2 pr-3">
                  <div class="label">Cena s DPH:</div>
                  <div class="value font-weight-bold font-large">{{currency(flatInfo.flat_price_before_discount_vat)}} €</div>
                </div>
                <div v-if="flatInfo.flat_status === '0'" class="d-flex justify-content-between align-items-center py-2 pr-3">
                  <div class="label text-blue font-weight-bold offer-price">Cena bytu s DPH s akciou Nový rok, staré ceny:</div>
                  <div class="value font-weight-bold font-large text-blue">{{currency(flatInfo.flat_price)}} €</div>
                </div>
                <div class="offer-info">
                  <p>Bližšie informácie nájdete v <a target="_blank" href="../../docs/podmienky-akcie-novy-rok-stare-ceny.pdf">Podmienkach akcie</a></p>
                </div>
                <div v-if="flatInfo.flat_status === '0'" class="d-flex justify-content-center mt-3 border-bottom mb-3 pb-4">
                  <div class="row buttons">
                    <div class="col-12 col-md-6 mb-4 mb-md-0 pl-md-0 pr-md-1">
                      <b-button @click="scrollToContact" class="btn btn-primary rounded-0 d-block">Mám záujem</b-button>
                    </div>
                    <div class="col-12 col-md-6 pr-md-0 pl-md-1">
                      <b-button :href="flatInfo.flat_pdf" target="_blank" squared variant="outline-primary" class="d-block hypo-calc">Stiahnuť PDF</b-button>
                    </div>
                  </div>
                </div>
                <div class="pt-3 px-3 d-flex align-items-center flex-column mb-4">
                  <p class="text-center">Vypočítajte si výšku svojej mesačnej splátky. Uvedený výpočet splátky hypotekárneho úveru je orientačný.</p>
                  <iframe src="https://hypokalkulacka.m7.sk/hypokalkulacka-na-web-2-black/calculator.php?w=300&h=255&r=0&pbc=21242b&hc=21242b&htc=ffffff&haec=1&bc=21242b&btc=ffffff&bhtc=7bb7d8&baec=0&c=0&l=0&t=1&hodnota_nehnutelnosti=100000" style="width: 300px; height: 255px; padding: 0px; overflow: hidden;" scrolling="no" frameborder="0"></iframe>
                </div>
                <p class="small-font mb-2 mb-md-0 text-white-50">Cena bytu nezahrňuje cenu parkovacieho státia.</p>
                <p class="small-font mb-2 mb-md-0 text-white-50">Cena vonkajšieho parkovacieho státia od 11 000 EUR</p>
                <p class="small-font mb-2 mb-md-0 text-white-50" v-if="flatInfo.building.building_stage === 'bory2b' || flatInfo.building.building_stage === 'bory4'">Cena vnútorného parkovacieho státia od 20&nbsp;200 EUR</p>
                <p class="small-font mb-2 mb-md-0 text-white-50" v-else>Cena vnútorného parkovacieho státia od 20&nbsp;200 EUR</p>
                <p class="small-font mb-2 mb-md-0 text-white-50">Pre každý byt je povinnosť zakúpiť pivničnú kobku. Developer si vyhradzuje právo zmeny cien dostupných bytov.</p>
                <p class="small-font mb-2 mb-md-0 text-white-50">* Byt nie je možné predrezervovať formou kontaktného formulára, byt sa považuje za rezervovaný až v momente podpisu zmluvy.</p>
                <p class="small-font mb-2 mb-md-0 mt-4 d-block d-md-none text-white-50">Uvedené rozmery a plochy jednotlivých miestností sú predbežné a môžu vykazovať odchýlky.</p>
                <p class="small-font mb-2 mb-md-0 d-block d-md-none text-white-50">Umiestnenie zariaďovacích predmetov je len ilustračné.</p>
              </div>
              <div class="col-12 col-lg-7 mb-4 mb-md-0">
                <div class="flat-image">
                  <img :src="flatInfo.flat_plan" alt="">
                  <b-button :href="flatInfo.flat_pdf" target="_blank" squared variant="outline-primary" class="pdf-button"></b-button>
                  <b-button :href="flatInfo.url_link_to_the_vr_apartments_tour" target="_blank" squared variant="outline-primary" class="virtual-button d-block d-md-none"></b-button>
                </div>
                <p class="small-font mb-2 mb-md-0 d-none d-md-block text-white-50">Uvedené rozmery a plochy jednotlivých miestností sú predbežné a môžu vykazovať odchýlky.</p>
                <p class="small-font mb-2 mb-md-0 d-none d-md-block text-white-50">Umiestnenie zariaďovacích predmetov je len ilustračné.</p>
              </div>
            </div>


          </b-tab>
          <b-tab title="Štandard" class="tab-standard">
            <div class="d-flex justify-content-center justify-content-md-start">
              <a v-if="flatInfo.building && flatInfo.building.building_stage === 'bory4'" href="../../docs/TS_NaHradzi_3.pdf" class="tech-spec-button" target="_blank"><span><img src="../assets/icons/tech-spec.svg" alt=""></span> Technický štandard bytu</a>
              <a v-if="flatInfo.building && flatInfo.building.building_stage === 'bory2b'" href="../../docs/TS_NovyDvor_4.pdf" class="tech-spec-button" target="_blank"><span><img src="../assets/icons/tech-spec.svg" alt=""></span> Technický štandard bytu</a>
              <a v-if="flatInfo.building && flatInfo.building.building_stage === 'bory3'" href="../../docs/TS_Promenada_2.pdf" class="tech-spec-button" target="_blank"><span><img src="../assets/icons/tech-spec.svg" alt=""></span> Technický štandard bytu</a>
            </div>
            <div id="standards-slider" v-if="flatInfo.building && flatInfo.building.building_stage === 'bory4'">
              <div class="na-hradzi">
                <slick
                    ref="slickStandard"
                    :options="slickOptions"
                    class="slider-for"
                >
                  <div class="slide slide-1">
                    <img src="/standards/na-hradzi/slide-1.jpg">
                    <div class="standards-info">
                      <div class="button-toggle info-1" @click="toggleBox('info-1')">
                        <div class="box box-1 p-3" data-box="info-1">
                          <h4>VYKUROVANIE A PRÍPRAVA TEPLEJ ÚŽITKOVEJ VODY</h4>
                          <p>Na ohrev teplej úžitkovej vody a vykurovanie slúžia v bytových domoch centrálne kotolne, ktoré sú umiestnené v podzemnom podlaží obytných blokov. Vykurovanie izieb bytov je zabezpečené doskovými vykurovacími telesami s termostatickými hlavicami, vykurovanie kúpeľní je zabezpečené rebríkovým radiátorom.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-2" @click="toggleBox('info-2')">
                        <div class="box box-2 p-3" data-box="info-2">
                          <h4>PODLAHY</h4>
                          <p>V predsieni, chodbe, obývacej izbe, izbách, kuchyni, vo vnútornom sklade alt. v šatníku alebo komore laminátová podlaha značky Egger.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-3" @click="toggleBox('info-3')">
                        <div class="box box-3 p-3" data-box="info-3">
                          <h4>DELIACE MEDZIBYTOVÉ STENY</h4>
                          <p class="mb-3">Železobetónové steny hrúbky 220 alebo 250 mm.</p>
                          <h4>DELIACE INTERIÉROVÉ PRIEČKY</h4>
                          <p>Sú z&nbsp;keramických tvárnic hrúbky 80, 115 alebo 140 mm.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide slide-2">
                    <img src="/standards/na-hradzi/slide-2.jpg">
                    <div class="standards-info">
                      <div class="button-toggle info-2-1" @click="toggleBox('info-2-1')">
                        <div class="box box-1 p-3" data-box="info-2-1">
                          <h4>OKNÁ</h4>
                          <p>Plastové okná s izolačným trojsklom. Farba okenných rámov a parapetov z vnútornej strany biela, z vonkajšej strany čiernosivá.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-2-2" @click="toggleBox('info-2-2')">
                        <div class="box box-2 p-3" data-box="info-2-2">
                          <h4>EXTERIÉR</h4>
                          <p>Na balkónoch, loggiách a terasách sú použité gresové dlažby do exteriéru, ktoré sú uložené na rektifikačných terčoch. Terasy predzáhradok majú podlahy založené v&nbsp;štrkových lôžkach.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-2-3" @click="toggleBox('info-2-3')">
                        <div class="box box-3 p-3" data-box="info-2-3">
                          <h4>TIENENIE</h4>
                          <p>Kastlík s vonkajšími elektrickými žalúziami.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide slide-3">
                    <img src="/standards/na-hradzi/slide-3.jpg">
                    <div class="standards-info">
                      <div class="button-toggle info-3-1" @click="toggleBox('info-3-1')">
                        <div class="box box-1 p-3" data-box="info-3-1">
                          <h4>VSTUPNÉ DVERE DO BYTU</h4>
                          <p>Vstupné bezpečnostné dvere v bezpečnostnej triede 3 s kovaním guľa-kľučka a s priezorníkom. Farba vstupných dverí z&nbsp;vonkajšej strany je&nbsp;šedobéžová (RAL 1019) a farba z vnútornej strany je biela (RAL 9003).</p>
                        </div>
                      </div>
                      <div class="button-toggle info-3-2" @click="toggleBox('info-3-2')">
                        <div class="box box-2 p-3" data-box="info-3-2">
                          <h4>INTERIÉROVÉ DVERE</h4>
                          <p class="mb-2">1,5 – 2-izb. byty – Polodrážkové dvere značky Lignis, plné dverné krídlo z DTD, CPL úprava,priznané nerezové závesy, klasické kovanie/kovanie s&nbsp;magnetickým zámkom.</p>
                          <p>3 – 4-izb. byty – Bezfalcové dvere značky Lignis, plné dverné krídlo z&nbsp;DTD, CPL úprava, skryté závesy, klasické kovanie/kovanie s&nbsp;magnetickým zámkom.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-3-3" @click="toggleBox('info-3-3')">
                        <div class="box box-3 p-3" data-box="info-3-3">
                          <h4>VZDUCHOTECHNIKA</h4>
                          <p class="mb-2">Predpríprava vzduchotechnického napojenia kuchynského digestora&nbsp;– odbočka zo stúpacieho vzduchotechnického potrubia.</p>
                          <p class="mb-2">Nútené vetranie kúpeľne a&nbsp;WC ventilátorom ovládaným samostatným vypínačom.</p>
                          <p class="mb-2">Hygienické vetranie bytu&nbsp;– akustické vetracie mriežky zabudované v&nbsp;obvodových stenách, podtlak zabezpečujú ventilátory vo WC a v&nbsp;kúpeľni.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide slide-4">
                    <img src="/standards/na-hradzi/slide-4.jpg">
                    <div class="standards-info">
                      <div class="button-toggle info-4-1" @click="toggleBox('info-4-1')">
                        <div class="box box-3 p-3" data-box="info-4-1">
                          <h4>VAŇA</h4>
                          <p>Smaltová oceľová vaňa značky Kaldewai vrátane vaňovej batérie a ručnej sprchy Grohe.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-4-2" @click="toggleBox('info-4-2')">
                        <div class="box box-1 p-3" data-box="info-4-2">
                          <h4>PODLAHA A OBKLAD</h4>
                          <p>Gressová dlažba značky Ragno Studio.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-4-3" @click="toggleBox('info-4-3')">
                        <div class="box box-2 p-3" data-box="info-4-3">
                          <h4>UMÝVADIELKO</h4>
                          <p>Umývadlo Duravit Vero / Duravit Durasquare vrátane umývadlovej batérie Grohe.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide slide-5">
                    <img src="/standards/na-hradzi/slide-5.jpg">
                    <div class="standards-info">
                      <div class="button-toggle info-5-1" @click="toggleBox('info-5-1')">
                        <div class="box box-1 p-3" data-box="info-5-1">
                          <h4>SPRCHA</h4>
                          <p>Sprchová zástena Huppe s gresovou dlažbou a sprchovým žľabom, vrátane sprchového setu a sprchovej batérie Grohe.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-5-2" @click="toggleBox('info-5-2')">
                        <div class="box box-2 p-3" data-box="info-5-2">
                          <h4>TOALETA</h4>
                          <p class="mb-2">1,5 – 2-izb. byty – Závesná rimless WC misa Duravit, podomietkový splachovací systém, ovládanie tlačítkom.</p>
                          <p class="mb-3">3 – 4-izb. byty – Závesná rimless WC misa Duravit so sedátkom so softclose, podomietkový splachovací systém, ovládanie tlačítkom.</p>
                          <h4>UMÝVADLO</h4>
                          <p>V samostatnom WC malé umývadielko Duravit Vero Air vrátane batérie Grohe.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </slick>
                <slick
                    ref="slickStandardNav1"
                    :options="slickOptionsNav1"
                    class="slider-nav"
                >
                  <div class="slide slide-1">
                    <img src="/standards/na-hradzi/slide-1.jpg">
                  </div>
                  <div class="slide slide-2">
                    <img src="/standards/na-hradzi/slide-2.jpg">
                  </div>
                  <div class="slide slide-3">
                    <img src="/standards/na-hradzi/slide-3.jpg">
                  </div>
                  <div class="slide slide-4">
                    <img src="/standards/na-hradzi/slide-4.jpg">
                  </div>
                  <div class="slide slide-5">
                    <img src="/standards/na-hradzi/slide-5.jpg">
                  </div>
                </slick>
              </div>
            </div>
            <div id="standards-slider" v-if="flatInfo.building && flatInfo.building.building_stage === 'bory2b'">
              <div class="novy-dvor">
                <slick
                    ref="slickStandard"
                    :options="slickOptions"
                    class="slider-for"
                >
                  <div class="slide slide-1">
                    <img src="/standards/novy-dvor/slide-1.jpg">
                    <div class="standards-info">
                      <div class="button-toggle info-1" @click="toggleBox('info-1')">
                        <div class="box box-1 p-3" data-box="info-1">
                          <h4>PODLAHY</h4>
                          <p>Predsieň, chodba, obývacia izba, izby, kuchyňa – laminátová podlaha značky Egger.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-2" @click="toggleBox('info-2')">
                        <div class="box box-2 p-3" data-box="info-2">
                          <h4>VSTUPNÉ DVERE DO BYTU</h4>
                          <p>Bezpečnostné, požiarne odolné, bytové dvere v bezpečnostnej triede 3; bezpečnostné kovanie; farba dverí aj zárubní z vnútornej aj vonkajšej strany bytu vo farbe (béžová).</p>
                        </div>
                      </div>
                      <div class="button-toggle info-3" @click="toggleBox('info-3')">
                        <div class="box box-3 p-3" data-box="info-3">
                          <h4>INTERIÉROVÉ DVERE</h4>
                          <p class="mb-2">1&nbsp;– 2-izb. byty&nbsp;– Polodrážkové dvere značky Lignis, plné dverné krídlo z&nbsp;DTD, CPL úprava, priznané nerezové závesy, klasické kovanie/kovanie s&nbsp;magnetickým zámkom.</p>
                          <p>3&nbsp;– 4-izb. byty&nbsp;– Bezfalcové dvere značky Lignis, plné dverné krídlo z&nbsp;DTD, CPL úprava, skryté závesy, klasické kovanie/kovanie s&nbsp;magnetickým zámkom. </p>
                        </div>
                      </div>
                      <div class="button-toggle info-4" @click="toggleBox('info-4')">
                        <div class="box box-4 p-3" data-box="info-4">
                          <h4>VZDUCHOTECHNIKA</h4>
                          <p class="mb-2">Predpríprava vzduchotechnického napojenia kuchynského digestora&nbsp;– odbočka zo stúpacieho vzduchotechnického potrubia s&nbsp;osadením spätnej klapky v&nbsp;mieste inštalačného jadra.</p> 
                          <p class="mb-2">Nútené vetranie kúpeľne a&nbsp;WC ventilátorom ovládaným samostatným vypínačom.</p>
                          <p>Hygienické vetranie bytu - akustické vetracie mriežky zabudované v&nbsp;okenných konštrukciách, podtlak zabezpečujú ventilátory vo WC a v&nbsp;kúpeľni.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide slide-2">
                    <img src="/standards/novy-dvor/slide-2.jpg">
                    <div class="standards-info">
                      <div class="button-toggle info-2-1" @click="toggleBox('info-2-1')">
                        <div class="box box-1 p-3" data-box="info-2-1">
                          <h4>VYKUROVANIE A PRÍPRAVA TEPLEJ ÚŽITKOVEJ VODY </h4>
                          <p>Zdrojom tepla na prípravu TÚV pre bytové domy je kotolňa umiestnená v podzemnom podlaží každého obytného bloku. Vykurovanie izieb je zabezpečené doskovými vykurovacími telesami s termoregulačnými hlavicami, v kúpeľni rebríkovým radiátorom.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-2-2" @click="toggleBox('info-2-2')">
                        <div class="box box-2 p-3" data-box="info-2-2">
                          <h4>DELIACE MEDZIBYTOVÉ STENY</h4>
                          <p class="mb-3">Železobetónové s hrúbkou 250 mm a 220 mm spĺňajúce požiadavky na zvukovú izoláciu deliacich konštrukcií podľa príslušnej normy STN.</p>
                          <h4>DELIACE INTERIÉROVÉ PRIEČKY</h4>
                          <p>Murované z tehál s hrúbkou 115 mm alebo 140 mm.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-2-3" @click="toggleBox('info-2-3')">
                        <div class="box box-3 p-3" data-box="info-2-3">
                          <h4>OKNÁ</h4>
                          <p>Plastové okná s izolačným trojsklom, farba rámu z vnútornej strany biela, z vonkajšej strany vo farbe (béžová), vonkajšie parapety vo farbe okenného rámu, vnútorný parapet bielej farby.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-2-4" @click="toggleBox('info-2-4')">
                        <div class="box box-4 p-3" data-box="info-2-4">
                          <h4>TIENENIE</h4>
                          <p>Podľa typu bytu vonkajšie žalúzie alebo predpríprava na vonkajšie žalúzie alebo posuvné perforované tieniace panely.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide slide-3">
                    <img src="/standards/novy-dvor/slide-3.jpg">
                    <div class="standards-info">
                      <div class="button-toggle info-3-1" @click="toggleBox('info-3-1')">
                        <div class="box box-1 p-3" data-box="info-3-1">
                          <h4>PODLAHA A OBKLAD</h4>
                          <p>Gresová dlažba a obklad Ragno Rewind.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-3-2" @click="toggleBox('info-3-2')">
                        <div class="box box-2 p-3" data-box="info-3-2">
                          <h4>UMÝVADLO</h4>
                          <p>Umývadlo Duravit Vero vrátane umývadlovej batérie Grohe.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-3-3" @click="toggleBox('info-3-3')">
                        <div class="box box-3 p-3" data-box="info-3-3">
                          <h4>VAŇA</h4>
                          <p>Smaltovaná vaňa Kaldewai vrátane vaňovej batérie a ručnej sprchy Grohe.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide slide-4">
                    <img src="/standards/novy-dvor/slide-4.jpg">
                    <div class="standards-info">
                      <div class="button-toggle info-4-1" @click="toggleBox('info-4-1')">
                        <div class="box box-1 p-3" data-box="info-4-1">
                          <h4>SPRCHOVY KÚT</h4>
                          <p>Sprchová zástena Roth vrátane sprchového setu a sprchovej batérie Grohe.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide slide-5">
                    <img src="/standards/novy-dvor/slide-5.jpg">
                    <div class="standards-info">
                      <div class="button-toggle info-5-1" @click="toggleBox('info-5-1')">
                        <div class="box box-1 p-3" data-box="info-5-1">
                          <h4>TOALETA</h4>
                          <p>Závesná rimless WC misa Duravit so sedátkom (s/bez softclose), podomietkový splachovací systém vrátane tlačidla.</p>
                        </div>
                      </div>
                      <div class="button-toggle info-5-2" @click="toggleBox('info-5-2')">
                        <div class="box box-2 p-3" data-box="info-5-2">
                          <h4>UMÝVADIELKO</h4>
                          <p>Malé umývadielko Duravit Vero Air vrátane batérie Grohe.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </slick>
                <slick
                    ref="slickStandardNav1"
                    :options="slickOptionsNav1"
                    class="slider-nav"
                >
                  <div class="slide slide-1">
                    <img src="/standards/novy-dvor/slide-1.jpg">
                  </div>
                  <div class="slide slide-2">
                    <img src="/standards/novy-dvor/slide-2.jpg">
                  </div>
                  <div class="slide slide-3">
                    <img src="/standards/novy-dvor/slide-3.jpg">
                  </div>
                  <div class="slide slide-4">
                    <img src="/standards/novy-dvor/slide-4.jpg">
                  </div>
                  <div class="slide slide-5">
                    <img src="/standards/novy-dvor/slide-5.jpg">
                  </div>
                </slick>
              </div>
            </div>
            <div class="accordion" role="tablist" v-if="flatInfo.building && flatInfo.building.building_stage !== 'bory4' && flatInfo.building.building_stage !== 'bory2b'">
              <b-card no-body class="mb-0" v-for="(standard, index) in flatStandards" :key="index">
                <b-card-header header-tag="header" class="p-0 d-flex justify-content-between" role="tab">
                  <b-button block v-b-toggle="'accordion-'+index">{{standard.title}}<span></span><span></span></b-button>
                </b-card-header>
                <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
                  <b-card-body v-html="standard.text">
                    
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <p class="small-font m-0 mt-4 text-white-50" v-if="flatInfo.building && flatInfo.building.building_stage !== 'bory4' && flatInfo.building.building_stage !== 'bory2b'">Všetky konštrukcie a povrchy budú zrealizované v zmysle platných noriem a predpisov.</p>
            <p class="small-font m-0 text-white-50" v-if="flatInfo.building && flatInfo.building.building_stage !== 'bory4' && flatInfo.building.building_stage !== 'bory2b'">Budúci predávajúci si vyhradzuje právo zmeny jednotlivých položiek a ich náhradu položkami porovnateľnej kvality.</p>
          </b-tab>
          <b-tab title="Financovanie">
            <div class="row finance mb-5">
              <div class="col-12 col-md-7" v-if="flatInfo.building && flatInfo.building.building_stage == 'bory3' || flatInfo.building.building_stage == 'bory2b'">
                <h4 class="mb-4">Splátkový kalendár</h4>
                <div class="d-flex box-wrapper">
                  <div class="box mr-0 mr-md-3">
                    <p class="mb-1">Prvá splátka</p>
                    <h4 style="font-size: 56px;">5 000 €</h4>
                  </div>
                  <div class="box">
                    <h4 class="mb-1" style="font-size: 30px;">Zvyšok kúpnej ceny</h4>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-7" v-else>
                <h4 class="mb-4">Splátkový kalendár 10/90</h4>
                <div class="d-flex box-wrapper">
                  <div class="box mr-0 mr-md-3">
                    <h4 class="mb-1">10 <span>%</span></h4>
                    <p>po podpise <strong>Zmluvy o&nbsp;budúcej zmluve</strong></p>
                  </div>
                  <div class="box">
                    <h4 class="mb-1">90 <span>%</span></h4>
                    <p>po kolaudácii</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <h4 class="md-3 mb-md-5">Možnosti financovania</h4>
                <p class="mb-3">Najvýhodnejšie možnosti financovania bytu v projekte Bory Bývanie <br>Vám poskytne náš hypotekárny poradca</p>
                <div class="contact">
                  <p class="mb-2 name">Ing. Darko Poljak, MSc.</p>
                  <p class="mb-2"><a href="tel:+421915717707">+421 915 717 707</a></p>
                  <p><a href="mailto:borybyvanie@hypouvery.sk">borybyvanie@hypouvery.sk</a></p>
                </div>
              </div>
            </div>
            <div class="sample p-md-3">
              <div class="d-flex flex-wrap">
                <div class="col-md-6 pt-md-4 pl-md-5 text-left">
                  <div class="category">Zaujímavosti</div>
                  <h3>Ako financovať vaše<br> bývanie na Boroch?</h3>
                  <p>Cesta k vášmu vysnívanému bývaniu nemusí byť <br>zložitá.Priblížime vám možnosti aj proces financovania<br> vašej novej nehnuteľnosti v našich projektoch.</p>
                  <a href="/novinky/ako-financovat-vase-byvanie-na-boroch" class="btn btn-primary rounded-0 py-3 font-weight-bold">Prečítajte si článok</a>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 img-bg ml-0 pr-0">
                  <img src="/gallery/bory2/interier/Bory-Byvanie-2-interier-6.jpg" alt="">
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab @click.prevent="goToPage($event)">
            <template #title>
              фінансування <img class="ml-2" src="../assets/images/ukraine.png" height="18" alt="">
            </template>
          </b-tab>
          <b-tab title="Postup pri kúpe">
            <div class="col-md-8 col-12 left-line mb-5">
              <div class="item py-3">
                <div class="num">1</div>
                <div class="item-header">
                  <p class="mb-0">Dohodnete si stretnutie s predajcom</p>
                </div>
              </div>

              <div class="item py-3">
                <div class="num">2</div>
                <div class="item-header">
                  <p class="mb-0">Podpíšete zmluvu o budúcej zmluve a zaplatíte 1. splátku vo výške 15%
                  </p>
                </div>
              </div>
              <div class="item py-3" v-if="flatInfo.building && flatInfo.building.building_stage === 'bory4'">
                <div class="num">3</div>
                <div class="item-header">
                  <p class="mb-0">Vyberiete si štandardné vybavenie bytu
                  </p>
                </div>
              </div>
              <div class="item py-3">
                <div class="num" v-if="flatInfo.building && flatInfo.building.building_stage === 'bory4'">4</div>
                <div class="num" v-else>3</div>
                <div class="item-header">
                  <p class="mb-0">Na technickej obhliadke si prezriete svoj budúci byt
                  </p>
                </div>
              </div>
              <div class="item py-3">
                <div class="num" v-if="flatInfo.building && flatInfo.building.building_stage === 'bory4'">5</div>
                <div class="num" v-else>4</div>
                <div class="item-header">
                  <p class="mb-0">Po kolaudácii zaplatíte zvyšnú časť kupnej ceny vo výške 85%
                  </p>
                </div>
              </div>
              <div class="item py-3">
                <div class="num" v-if="flatInfo.building && flatInfo.building.building_stage === 'bory4'">6</div>
                <div class="num" v-else>5</div>
                <div class="item-header">
                  <p class="mb-0">Podpíšete kúpnu zmluvu a preberiete si svoj byt</p>
                </div>
              </div>
            </div>
            <b-button @click="scrollToFooter" class="btn btn-primary rounded-0 d-block">Dohodnúť si stretnutie s predajcom</b-button>
          </b-tab>
          <b-tab title="Harmonogram výstavby">
            <div class="accordion" role="tablist">
              <!-- BORY 4 -->
              <div class="d-flex flex-wrap schedule" v-if="flatInfo.building && flatInfo.building.building_stage === 'bory4'">
                <div class="col-md p-0 phase fill">
                  <div class="icon">
                    <img src="../assets/icons/zahajenie-vystavby.svg">
                  </div>
                  <h3>Začiatok<br> výstavby</h3>
                  <div class="line fill-min"></div>
                  <p class="date">3. kvartál 2021</p>
                </div>
                <div class="col-md p-0 phase">
                  <div class="icon">
                    <img src="../assets/icons/hruba-stavba.svg">
                  </div>
                  <h3>Žiadosť<br> o kolaudáciu</h3>
                  <div class="line"></div>
                  <p class="date">3. kvartál 2023</p>
                </div>
                <div class="col-md p-0 phase">
                  <div class="icon">
                    <img src="../assets/icons/kolaudacia.svg">
                  </div>
                  <h3>Kolaudačný<br> proces</h3>
                  <div class="line"></div>
                  <p class="date">4. kvartál 2023</p>
                </div>
                <div class="col-md p-0 phase">
                  <div class="icon">
                    <img src="../assets/icons/odovzdanie-bytov.svg">
                  </div>
                  <h3>Odovzdávanie<br> bytov</h3>
                  <div class="line"></div>
                  <p class="date">1. - 2. kvartál 2024</p>
                </div>
                <div class="col-md p-0 phase">
                  <div class="icon">
                    <img src="../assets/icons/kluce-byvanie.svg">
                  </div>
                  <h3>Vaše<br> bývanie</h3>
                  <div class="line transparent"></div>
                </div>
              </div>
              <!-- BORY 3 -->
              <div class="d-flex flex-wrap schedule" v-if="flatInfo.building && flatInfo.building.building_stage === 'bory3'">
                <div class="col-md p-0 phase fill">
                  <div class="icon">
                    <img src="../assets/icons/zahajenie-vystavby.svg">
                  </div>
                  <h3>Začiatok<br> výstavby</h3>
                  <div class="line fill"></div>
                  <p class="date">3. kvartál 2020</p>
                </div>
                <div class="col-md p-0 phase fill">
                  <div class="icon">
                    <img src="../assets/icons/hruba-stavba.svg">
                  </div>
                  <h3>Žiadosť<br> o kolaudáciu</h3>
                  <div class="line fill-min"></div>
                  <p class="date">3. kvartál 2022</p>
                </div>
                <div class="col-md p-0 phase">
                  <div class="icon">
                    <img src="../assets/icons/kolaudacia.svg">
                  </div>
                  <h3>Kolaudačný<br> proces</h3>
                  <div class="line"></div>
                  <p class="date">3. - 4. kvartál 2022</p>
                </div>
                <div class="col-md p-0 phase">
                  <div class="icon">
                    <img src="../assets/icons/odovzdanie-bytov.svg">
                  </div>
                  <h3>Odovzdávanie<br> bytov</h3>
                  <div class="line"></div>
                  <p class="date">1. - 3. kvartál 2023</p>
                </div>
                <div class="col-md p-0 phase">
                  <div class="icon">
                    <img src="../assets/icons/kluce-byvanie.svg">
                  </div>
                  <h3>Vaše<br> bývanie</h3>
                  <div class="line transparent"></div>
                </div>
              </div>
              <!-- BORY 2 -->
              <div class="schedule" v-if="flatInfo.building && flatInfo.building.building_stage === 'bory2' || flatInfo.building.building_stage === 'bory2b'">
                <h3 class="mb-3">2. Fáza</h3>
                <div class="d-flex flex-wrap">
                  <div class="col-md p-0 phase fill">
                    <div class="icon">
                      <img src="../assets/icons/zahajenie-vystavby.svg">
                    </div>
                    <h3>2. fáza</h3>
                    <h3>Začiatok<br> výstavby</h3>
                    <div class="line fill-min"></div>
                    <p class="date">1. kvartál 2021</p>
                  </div>
                  <div class="col-md p-0 phase">
                    <div class="icon">
                      <img src="../assets/icons/hruba-stavba.svg">
                    </div>
                    <h3>2. fáza</h3>
                    <h3>Koniec<br> výstavby</h3>
                    <div class="line"></div>
                    <p class="date">2. kvartál 2023</p>
                  </div>
                  <div class="col-md p-0 phase">
                    <div class="icon">
                      <img src="../assets/icons/kolaudacia.svg">
                    </div>
                    <h3>2. fáza</h3>
                    <h3>Kolaudačný<br> proces</h3>
                    <div class="line"></div>
                    <p class="date">3. kvartál 2023</p>
                  </div>
                  <div class="col-md p-0 phase">
                    <div class="icon">
                      <img src="../assets/icons/odovzdanie-bytov.svg">
                    </div>
                    <h3>2. fáza</h3>
                    <h3>Odovzdávanie<br> bytov</h3>
                    <div class="line"></div>
                    <p class="date">4. kvartál 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <template #tabs-end>
            <b-nav-item class="virtual" :href="flatInfo.url_link_to_the_vr_apartments_tour" target="_blank" role="presentation" @click="() => {}" v-if="flatInfo.building && flatInfo.url_link_to_the_vr_apartments_tour">Virtuálna prehliadka</b-nav-item>
          </template>
        </b-tabs>
      </div>

    </div>
    <ContactForm :internalId="flatInfo.flat_internal_id" :stage="flatInfo.building.building_stage"></ContactForm>
    <div class="d-flex spinner" v-if="isLoading">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import standards from "@/store/standards.json"
import ContactForm from "@/components/ContactForm";
import Slick from "vue-slick";
import { mapGetters, mapActions } from 'vuex'
import collect from 'collect.js'

function setCookie(name,value,days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

export default {
  name: "FlatDetail",
  components: {ContactForm, Slick},
  computed: {
    ...mapGetters('flats', ['flats'])
  },
  data() {
    return {
      selectedItems: [],
      isLoading: false,
      flatId: this.$route.params.id,
      flatInfo: [],
      stage: this.$route.params.stage,
      flatStandards: null,
      filter: this.$route.params.filter,
      floor: this.$route.params.floor,
      building: this.$route.params.building,
      exterierArea: null,
      prevRoute: null,
      tabIndex: 0,
      compare: [],
      compareArr: [],
      isInCompare: false,
      loaded: false,
      slickOptions: {
        infinite: false,
        adaptiveHeight: true,
        dots: false,
        arrows: false,
        asNavFor: '.slider-nav'
        // Any other options that can be got from plugin documentation
        // responsive: [
        //   {
        //     breakpoint: 1024,
        //     settings: {
        //       slidesToShow: 2
        //     }
        //   }
        //   // You can unslick at a given breakpoint now by adding:
        //   // settings: "unslick"
        //   // instead of a settings object
        // ]
      },
      slickOptionsNav1: {
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        asNavFor: '.slider-for',
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              dots: true
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      }
    }
  },
  async mounted() {
    window.scrollTo(0, 0)
    this.isLoading = true
    await this.loadFlats()
    await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/apartment-houses/` + this.stage + '/' + this.flatId)
        .then((response) => {
          this.flatInfo = response.data.data
          let stndrd = null
          if (response.data.data.building.building_stage === 'bory3')
            stndrd = standards.main.bory3
          else if (response.data.data.building.building_stage === 'bory4')
            stndrd = standards.main.bory4
          else
            stndrd = standards.main.bory2
          this.flatStandards = stndrd
          this.isLoading = false
          const exterier = [
            response.data.data.flat_area_balcony,
            response.data.data.flat_area_loggia,
            response.data.data.flat_area_loggia2,
            response.data.data.flat_area_front_garden,
            response.data.data.flat_area_garden,
            response.data.data.flat_area_external_storage1,
            response.data.data.flat_area_external_storage2,
            response.data.data.flat_area_terrace,
            response.data.data.flat_area_terrace2,
          ]
          const toNumber = n => isNaN(n) ? 0 : Number(n)
          this.exterierArea = exterier.reduce((a, b) => toNumber(a) + toNumber(b)).toFixed(2)
          document.title = this.flatInfo.flat_penta_internal_id ? 'Byt ' + this.flatInfo.flat_penta_internal_id + ' - Bory bývanie' : 'Detail bytu'
          this.loaded = true
        })

      if (getCookie('compareFlats')) {
        const selItems = this.selectedItems
        const compareFlats = JSON.parse(getCookie('compareFlats'))
        this.isInCompare = compareFlats.includes(this.flatInfo.flat_penta_internal_id)
        this.compareArr = compareFlats.length
        this.compare = collect(this.flats).whereIn('flat_penta_internal_id', compareFlats).all()
        this.compare.forEach(element => {
          selItems.push(element.flat_penta_internal_id)
        });
      }
  },
  methods: {
    ...mapActions('flats', ['loadFlats']),
    toCompareTable(e) {
      if (e.target.classList.contains('delete-item')) {
        return
      }
      this.$router.push({
        name: 'Flats',
        hash: '#compare-table',
        params: {floor: this.floor, building: this.building},
        query: { filter: JSON.stringify(this.filter) }
      })
    },
    addToCompare(data) {
      if (this.compare.length >= 4) {
        this.$bvToast.toast(`Do porovnania môžete pridať maximálne 4 byty`, {
          title: `Porovnanie bytov`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          variant: 'info',
          appendToast: true
        })
        return
      }
      const aa = collect(data).only('flat_penta_internal_id','flat_price', 'flat_number_of_rooms').all()
      console.log(aa)
      this.compare.push(aa)
      this.selectedItems.push(aa)
      let compareFlats = JSON.parse(getCookie('compareFlats'))
      if (!compareFlats) {
        compareFlats = []
      }
      compareFlats.push(data.flat_penta_internal_id)
      setCookie('compareFlats', JSON.stringify(compareFlats), 1)
      this.isInCompare = true
    },
    removeFromCompare(data) {
      const aaa = this.compare.findIndex(p => p.flat_penta_internal_id == data.flat_penta_internal_id);
      this.compare.splice(aaa, 1);
      const compareFlats = JSON.parse(getCookie('compareFlats'))
      const flatId = compareFlats.findIndex(p => p == data.flat_penta_internal_id);
      compareFlats.splice(flatId, 1)
      this.selectedItems.splice(flatId, 1)
      setCookie('compareFlats', JSON.stringify(compareFlats), 1)
      this.isInCompare = false
      this.compareArr = this.compare - 1
    },
    goToPage(e) {
      e.preventDefault()
      e.stopPropagation()
      const routeData = this.$router.resolve({name: 'UaFinancovanie'});
      window.open(routeData.href, '_blank');
    },
    tabChange(e) {
      if (e == 3) {
        this.tabIndex = 2
      }
      if (this.stage === 'bory2b' || this.stage === 'bory4')
        this.$refs.slickStandard.reSlick();
        this.$refs.slickStandardNav1.reSlick();
    },

    scrollToContact() {
      const contactForm = document.getElementById('contact-form')
      contactForm.scrollIntoView({behavior: "smooth", block: 'center'})

    },
    scrollToFooter() {
      const contactForm = document.getElementById('contact-footer')
      contactForm.scrollIntoView({behavior: "smooth", block: 'start'})

    },
    currency: price => {
      let value = parseFloat(price)
      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return `${value}`
    },
    backToFlats() {
      console.log(this.prevRoute)
      this.$router.push({
        name: this.prevRoute.name ? this.prevRoute.name : 'Flats',
        hash: '#tabulka',
        params: {floor: this.floor, building: this.building},
        query: { filter: JSON.stringify(this.filter) }
      })
    },
    toggleBox(val) {
      document.querySelector('.' + val).classList.toggle('clicked')
      document.querySelector('[data-box="' + val + '"]').classList.toggle('show')
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

.offer-price
  font-size: 18px
  @media (max-width: $md)
    max-width: 50%

.offer-info
  margin-bottom: 40px
  p
    font-size: 14px
    color: var(--blue)

    a
      text-decoration: underline

.test
  min-height: 1500px

.tech-spec-button
  color: #fff
  font-size: 16px
  margin-bottom: 30px
  display: inline-block
  margin-left: 10px
  display: flex
  align-items: center
  box-shadow: inset 48px 0 0 0 var(--blue)
  -webkit-transition: ease-out 0.4s
  -moz-transition: ease-out 0.4s
  transition: ease-out 0.4s
  text-decoration: none
  padding-right: 15px

  @media (max-width: $md)
    font-size: 14px
    background: var(--blue)
    margin-left: 0
    box-shadow: none

  &:hover
    box-shadow: inset 330px 0 0 0 var(--blue)

  span
    display: flex
    align-items: center
    justify-content: center
    margin-right: 10px
    width: 48px
    height: 48px

    @media (max-width: $md)
      margin-right: 5px

.compare-button
  background: #DCF2FF
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.2)
  position: fixed
  right: 60px
  bottom: 80px
  z-index: 1099
  cursor: pointer
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 15px 25px
  color: #001F33

  .compare-flats-detail
    font-size: 14px

  @media (max-width: $md)
    right: auto
    left: 0
    bottom: 0
    width: 100%

  .small-text
    font-size: 16px

  a
    color: #001F33
    padding-right: 20px
    position: relative

    &:after
      content: ""
      width: 10px
      height: 14px
      background: url('../assets/icons/arr-dark.svg') no-repeat
      background-size: contain
      position: absolute
      right: 0
      top: 5px

  .compareNum
    background: var(--blue)
    position: absolute
    right: -6px
    top: -6px
    color: #ffffff
    width: 24px
    height: 24px
    border-radius: 50%
    text-align: center

.slider-for
  margin-bottom: 15px

  @media (max-width: $md)
    margin-bottom: 5px

  .slick-slide
    margin: 0

.slider-nav
  .slide
    cursor: pointer
    position: relative

    &:hover
      &:before
        content: ""
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))

      &:after
        content: ""
        width: 22px
        height: 22px
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        background: url(../assets/icons/plus.svg) no-repeat
        background-size: contain


.buttons
  width: 100%

  .hypo-calc
    &:hover
      background: #7CB6D9
      color: #001F33

  button
    width: 100%
.flat-title
  font-size: 40px
  line-height: 45px
  font-weight: normal
  margin-bottom: 0

  @media (max-width: $md)
    font-size: 28px

.flat-image
  position: relative

  .pdf-button,
  .virtual-button
    width: 49px
    height: 49px
    background: #7CB6D9
    border: none
    position: absolute
    bottom: 20px
    left: 20px
    margin: 0
    transition: none

    @media (max-width: $md)
      left: 10px
      bottom: 10px

    &:before
      content: ""
      width: 25px
      height: 30px
      background: url('../assets/icons/pdf-icon.svg') no-repeat
      background-size: contain
      position: absolute
      left: 50%
      top: 50%
      transform: translate(-50%, -50%)

    &:hover
      background: #CFEDFF
      box-shadow: 0px 20px 48px rgba(0, 0, 0, 0.3)

      &:before
        background: url('../assets/icons/pdf-icon-hover.svg') no-repeat
        background-size: contain

  .virtual-button
    left: 65px

    &::before
      width: 33px
      height: 19px
      background: url('../assets/icons/360.svg') no-repeat
      background-size: contain

    &:hover
      &::before
        background: url('../assets/icons/360.svg') no-repeat
        background-size: contain
        
.tab-standard
  @media (max-width: $md)
    margin-left: -15px
    margin-right: -15px

#standards-slider
  max-width: 100%
  overflow: hidden
  .slide
    position: relative
  .novy-dvor
    .slide-1
      .standards-info
        .button-toggle
          &.info-1
            left: 22%
            top: 82%
          &.info-2
            left: 16%
            top: 33%
          &.info-3
            left: 16%
            top: 43%
          &.info-4
            left: 68%
            top: 24%
    .slide-2
      .standards-info
        .button-toggle
          &.info-2-1
            left: 42%
            top: 65%
          &.info-2-2
            left: 92%
            top: 30%
          &.info-2-3
            left: 53%
            top: 48%
          &.info-2-4
            left: 61%
            top: 48%
    .slide-3
      .standards-info
        .button-toggle
          &.info-3-1
            left: 55%
            top: 87%
          &.info-3-2
            left: 25%
            top: 20%
          &.info-3-3
            left: 50%
            top: 20%
    .slide-4
      .standards-info
        .button-toggle
          &.info-4-1
            left: 40%
            top: 40%
    .slide-5
      .standards-info
        .button-toggle
          &.info-5-1
            left: 55%
            top: 50%
          &.info-5-2
            left: 22%
            top: 45%
  .na-hradzi
    .slide-1
      .standards-info
        .button-toggle
          &.info-1
            left: 8%
            top: 80%
          &.info-2
            top: auto
            bottom: 3%
            left: 56%
          &.info-3
            top: 30%
            left: 90%
    .slide-2
      .standards-info
        .button-toggle
          &.info-2-1
            left: 23%
            top: 25%
          &.info-2-2
            top: auto
            bottom: 25%
            left: 22%
          &.info-2-3
            top: 12%
            left: 38%
    .slide-3
      .standards-info
        .button-toggle
          &.info-3-1
            left: 62%
            top: 40%
          &.info-3-2
            top: 58%
            left: 62%
          &.info-3-3
            top: 50%
            left: 95%
    .slide-4
      .standards-info
        .button-toggle
          &.info-4-1
            left: 21%
            top: auto
            bottom: 15%
          &.info-4-2
            top: auto
            bottom: 10%
            left: 48%
          &.info-4-3
            top: auto
            bottom: 18%
            left: 80%
    .slide-5
      .standards-info
        .button-toggle
          &.info-5-1
            left: 43%
            top: 50%
          &.info-5-2
            top: auto
            bottom: 20%
            left: 52%

.novy-dvor
  .slide-1
    .standards-info
      .box
        &.box-1
          top: 0px
          left: 50px
        &.box-2
          top: auto
          bottom: 50px
          left: -160px
        &.box-3
          top: 50px
          left: -160px
          bottom: auto
        &.box-4
          top: 50px
          left: -160px
          bottom: auto

          @media (max-width: $md)
            top: 0!important
            left: 35px !important
  .slide-2
    .standards-info
      .box
        &.box-1
          right: 50px
          top: -30px
        &.box-2
          right: 50px
          bottom: -50px
        &.box-3
          right: 50px
          bottom: 0px
        &.box-4
          left: 0
          top: 50px
  .slide-3
    .standards-info
      .box
        &.box-1
          left: -160px
          bottom: 50px
        &.box-2
          left: -160px
          top: 50px
        &.box-3
          left: -160px
          top: 50px
  .slide-4
    .standards-info
      .box
        &.box-1
          left: -160px
          top: 50px
  .slide-5
    .standards-info
      .box
        &.box-1
          left: -160px
          top: 50px
        &.box-2
          left: -160px
          top: 50px
.na-hradzi
  .slide-1
    .standards-info
      .box
        &.box-1
          top: -160px
          left: 50px
        &.box-2
          top: auto
          bottom: 50px
          left: -160px
        &.box-3
          top: 0
          left: auto
          right: 50px
  .slide-2
    .standards-info
      .box
        &.box-1
          top: 50px
          right: -160px
        &.box-2
          top: 0
          left: 50px
        &.box-3
          top: 0
          left: 50px
  .slide-3
    .standards-info
      .box
        &.box-1
          bottom: 0
          left: auto
          right: 50px
        &.box-2
          top: 0
          left: auto
          right: 50px
        &.box-3
          bottom: 0
          left: auto
          right: 50px
  .slide-4
    .standards-info
      .box
        &.box-1
          top: auto
          bottom: 55px
          left: -160px
        &.box-2
          top: auto
          bottom: 55px
          left: -160px
        &.box-3
          top: auto
          bottom: 55px
          left: -160px
  .slide-5
    .standards-info
      .box
        &.box-1
          top: auto
          bottom: 0
          left: 55px
        &.box-2
          top: -100px
          left: auto
          right: 55px

          @media (max-width: $md)
            top: 0!important
            left: 35px !important
.standards-info
  .box
    display: none
    position: absolute
    background: rgba(255, 255, 255, .8)
    width: 370px
    box-shadow: 0px 15px 40px rgba(112, 144, 176, 0.4)

    @media (max-width: $md)
      left: 30px !important
      top: 30px !important
      bottom: auto !important
      right: auto !important
      width: 80vw
      background: #fff

    &.show
      display: block

    h4, p
      color: var(--darkBlue)

    h4
      font-size: 18px
      font-weight: bold

      @media (max-width: $md)
        text-transform: capitalize

    p
      margin-bottom: 0
      font-size: 16px

      @media (max-width: $md)
        font-size: 14px
        line-height: 21px
        -webkit-font-smoothing: none
  .button-toggle
    position: absolute
    width: 44px
    height: 44px
    background: var(--blue)
    top: 50%
    left: 50%
    transform: translate(-50%, -50%) rotate(360deg)
    border-radius: 50%
    cursor: pointer
    z-index: 100
    box-shadow: 0px 15px 40px rgba(112, 144, 176, 0.4)
    transition: 0.8s cubic-bezier(0.2, 0.6, 0.3, 1.1)

    @media (max-width: $md)
      width: 32px
      height: 32px

  .button-toggle:after
    content: ''
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    height: 2px
    width: 50%
    background: white

    @media (max-width: $md)
      height: 1px

  .button-toggle:before
    content: ''
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    height: 50%
    width: 2px
    background: white

    @media (max-width: $md)
      width: 1px

  .button-toggle.clicked
    //transform: translate(-50%, -50%) rotate(180deg)
    background: var(--textDark)
    @media (max-width: $md)
      left: 25px !important
      top: 25px !important
      bottom: auto !important
      right: auto !important

      & ~ .button-toggle
        display: none

  .button-toggle.clicked:before
    width: 0

.back-button
  color: #fff
  font-weight: 500
  padding-left: 30px
  position: relative

  &:before
    content: ""
    width: 9px
    height: 17px
    background: url('../assets/icons/arr-left.svg') no-repeat
    background-size: contain
    position: absolute
    left: 0
    top: 5px

.small-font
  font-size: 14px
.btn-outline-primary
  color: #7CB6D9
  border: 1px solid #7CB6D9
  padding-top: 10px
  padding-bottom: 10px
.left-line
  position: relative
  &:before
    content: ""
    width: 2px
    height: 85%
    background: var(--primary)
    position: absolute
    left: 0
    top: 50%
    transform: translateY(-50%)
  .item
    position: relative
    border-bottom: 1px solid rgba(255, 255, 255, 0.2)
    margin-left: 60px
    @media (max-width: $md)
      margin-left: 20px
    &:before
      content: ""
      width: 36px
      height: 36px
      background: var(--primary)
      position: absolute
      left: -92px
      top: 50%
      transform: translateY(-50%)
      border-radius: 50%
      border: 3px solid #001F33

      @media (max-width: $md)
        left: -51px

    .num
      position: absolute
      left: -78px
      top: 50%
      transform: translateY(-50%)
      font-size: 14px
      color: #001F33

      @media (max-width: $md)
        left: -37px
.finance
  .box-wrapper
    @media (max-width: $md)
      flex-direction: column
  .box
    border: 1px solid rgba(255, 255, 255, 0.1)
    width: 260px
    height: 260px
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    padding: 25px
    text-align: center

    @media (max-width: $md)
      width: 100%
      height: auto
      margin-bottom: 15px

    h4
      font-size: 76px
      font-weight: 800
      color: var(--primary)

      span
        font-size: 32px
        font-weight: 300

  .contact
    .name
      font-size: 18px
      font-weight: 500

    a
      color: var(--primary)
  p
    font-size: 16px
    font-weight: 400
.hypo
  p
    margin-bottom: 0

    a
      color: #fff
      font-size: 16px
.flat-info
  @media (max-width: $md)
    order: 2
  h3
    padding-top: 27px
    margin-bottom: 41px
.hypokalkulacka
  width: 300px
  margin: auto
  display: block
  height: 285px
  overflow: hidden
.font-large
  font-size: 26px
.flatDetail
  padding-top: 120px
  padding-bottom: 50px
  background: var(--darkBlue)

  @media (max-width: $md)
    padding-top: 90px

.sample
  background: var(--textBright)

  @media (max-width: $md)
    padding: 15px 0

  .category
    color: var(--primary)
    font-size: 16px
    font-weight: 600
    margin-bottom: 30px

    @media (max-width: $md)
      margin-bottom: 15px

  h3
    font-size: 40px
    line-height: 45px
    font-weight: 400
    margin-bottom: 20px

    @media (max-width: $md)
      font-size: 28px
      line-height: 32px

  p
    font-size: 16px
    margin-bottom: 40px
    line-height: 150%
</style>